import APIService from './apiservice';
const apiUrl = 'OP/Patient'
class PatientSearch {

    async getPatientByUID(query) {
        return await APIService.getAsync(`${apiUrl}/SearchByUID/${query}`)
    }

    async getPatientByName(query) {
        return await APIService.getAsync(`${apiUrl}/SearchByName/${query}`)
    }

    async getPatientByMobileNo(query) {
        return await APIService.getAsync(`${apiUrl}/SearchByMobileNo/${query}`)
    }
    async getPatients(query){
        return await APIService.getAsync(`${apiUrl}?name=${query}`)
    }
    async getPatientById(patientId){
        return await APIService.getAsync(`${apiUrl}/${patientId}`)
    }
   
    async getById(id) {
        return await APIService.getAsync(`${apiUrl}/${id}`)
    }

    async getAdmittedInfoById(query) {
        return await APIService.getAsync(`${apiUrl}/AdmittedInfo/${query}`)
    }

    async BlockOrActiveAsync(data) {

        return await APIService.putAsync('OP/Patient', data)
    }
}

export default new PatientSearch()