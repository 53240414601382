export const OP_PATIENT_REGISTRATION = '/d/screen?q=2c571b7a-edd8-4166-8685-c81bff3f9f39';
export const OP_APPOINTMENT_BOOKING = '/m/OP/BookAppointment/?';
export const OP_CONSULTATION_BILL_PRINT = '#/print/OP/ConsultBill?';
export const OP_PRESCRIPTION = '/m/OP/Prescription?a='
export const OP_SERVICE_BILLING = '/m/OP/ServiceBilling/New?a='
export const OP_BILLING_SHOW = `Show?`
export const OP_CONSULTATION_BILLING = '/m/OP/ConsultationBilling'
export const OP_CONSULTATION_BILLING_PAGINATE = `Billing/OPConsultation/Paginate`
export const OP_EMR_PAGINATE = 'OP/EMR/Paginate'
export const OP_EMR_UPDATE = '/m/OP/EMR/Update/?'
export const OP_APPOINTMENT = '/m/OP/Appointment';
export const OP_ADMISSIONS = '/m/OP/Admission';

export const CLAIM_FOLLOWUP = '/m/claim/reminder';
export const CLAIM_ORGANIZATION_CLAIM = '/m/Claim/Claim';
export const CLAIM_PRE_AUTH = '/m/Claim/PreAuth';
export const CLAIM_ORGANIZATION_AMOUNT_RECEIVED = '/m/claim/Received';

export const IP_ADMISSION = '/m/IP/Admission/';
export const IP_AX_PAGE = '/m/IP/list/';
export const IP_AX_LIST_PRIMARY = '/m/IP/screen/add?';
export const IP_BED_OCCUPANCY = '/m/IP/BedOccupancy';
export const IP_BILLING = `/m/IP/Invoice`;
export const IP_REQUEST = `/m/OP/ServiceBilling`
export const IP_ORDER_APPROVAL = `/m/IP/Request`;
export const IP_BILLING_PRINT = '#/print/IP/Invoice/receipt?'
export const IP_BILLING_FINAL_PRINT = '#/print/IP/Invoice/Show?'
export const IP_SERVICE_BILLING = `/m/OP/ServiceBilling`
export const IP_REQUEST_BILLING = `/m/IP/Request`
export const IP_PATIENT_MOVEMENT = `/m/IP/PatientMovement`

export const LAB_REPORT = `/m/Lab/Template`
export const LAB_TEMPLATE_PAGINATE = 'Lab/Template/Paginate'

export const NURSING_AXPAGE = '/m/Nursing/list/'
export const NURSING_LIST_PRIMARY = '/m/Nursing/screen/add?'
export const NURSING_INITIAL_ASSESSMENT = '/d/list/'
export const NURSING_LAB_ORDER = `/m/Nursing/LabOrder`
export const NURSING_LAB_ORDER_SHEET_PAGINATE = `IP/ServiceOrder/Paginate`
export const NURSING_MEDICINE_ORDER = '/m/Nursing/MedicineOrder'
export const NURSING_MEDICINE_ORDER_PAGINATE = 'IP/PharmacyOrder/Paginate'
export const NURSING_SERVICE_REQUEST = `/m/Nursing/ServiceRequest`
export const NURSING_SERVICE_REQUEST_PAGINATE = `Nursing/ServiceRequest/Paginate`
export const NURSING_WARDINDENT = `Nursing/WardIndent/Paginate`
export const NURSING_WARDINDENT_RETURN = `Nursing/WardIndent/returnList`
export const NURSING_PATIENT_MEDICINE_INDENT = `Nursing/Indent/paginate`
export const NURSING_PATIENT_MEDICINE_RETURN = `Nursing/IndentReturn/paginate`
export const NURSING_PATIENT_MEDICINE_RECEIVED = `Nursing/IndentReturn/ReturnList`



export const NURSING_ISSUEDLIST = `Nursing/Indent/IssuedList`

export const OT_CONSUME = `/m/OT/Consume/OT`;
export const OT_BOOKING = `/m/OT/OTBooking`;
export const OT_SURGERY = `/m/OT/Surgery`;
export const OT_REQUEST = `/m/OT/Request`;

export const PHARMACY_INVOICE_LIST = '/m/Pharmacy/Invoice/List'
export const PHARMACY_INVOICE_LIST_SHOW = `/m/Pharmacy/Invoice/List/Show?`;
export const PHARMCY_INVOICE_PRINT = `/m/Pharmacy/Invoice/Print?`
export const PHARMACY_PURCHASE_ORDER_PAGINATE = '/Pharmacy/PurchaseOrder/Paginate'
export const PAHRMACY_PO = "Pharmacy/PurchaseOrder";
export const PHARMCY_SALES_RETURN = '/m/Pharmacy/SalesReturn';
export const PHARMCY_STOCK_TRANSFER = '/m/Pharmacy/Return'

export const VENDOR_MASTER_GETLIST = "Vendor/VendorMaster/GetList";
export const VENDOR_PAYMENT = '/m/Vendor/Payment'

export const SETTINGS_ROLE = '/m/Settings/Role'
export const SETTING_ROLE_UPDATE = '/m/Settings/Roles/Update?r=';

export const SETUP_RATE_CHART = '/m/Setup/RateChart'
export const USER_PAGES_LOGIN_BOX = '/Dashboard/User'

export const INVENTORY_GON_MODIFICATION = '/m/Inventory/GonApproval/Modify'
export const INVENTORY_GON_APPROVAL = '/m/Inventory/GONApproval'
export const INVENTORY_STOCK_ADJUSTMENT = '/m/Inventory/Adjustment'
export const INVENTORY_STOCK_CONSUME = '/m/Inventory/Consumption'
export const INVENTORY_GRN_ACKNOWLEDGE = '#/print/Inventory/GRNAcknowledge?'
export const INVENTORY_PR_APPROVAL1 = '/m/Pharmacy/PRApprovalOne'
export const INVENTORY_PR_APPROVAL2 = '/m/Inventory/PRApprovalTwo'



