import React, { createContext, useReducer } from 'react';

const initialState = {};
const AccessDataContext = createContext(initialState);
const { Provider } = AccessDataContext;

const AccessDataProvider = ({ children }) => {
    const [accessState, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "Data":
                return {
                    ...state,
                    data: action.payload
                }
            default:
                return {
                    ...state
                }
        }
    }, initialState);

    return <Provider value={{ accessState, dispatch }}>{children}</Provider>;
}

export { AccessDataContext, AccessDataProvider }