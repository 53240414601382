import APIService from '../apiservice';

class SettingsService{
    async getSequenceNo(){
      return  await APIService.getAsync('SequenceNo');
    }

    async putSequenceNo(items){
     return   await APIService.putAsync('SequenceNo', items)
    }

    async getPages(){
       return await APIService.getAsync('Role/Pages')
    }
    async getReports(){
        return await APIService.getAsync('Role/Reports')
     }
    async getRolePagesById(roleId){
      return  await APIService.getAsync('Role/' + roleId)
    }
    async updateAsync(data) {
        if (data.id) {
            await APIService.putAsync('Role', data);
        } else {
            await APIService.postAsync('Role', data);
        }
    }
    
    async postLoginBox(username, password) {
        return await APIService.postAsync('Auth', {username, password})
    }

    async getRoleList(){
       return await APIService.getAsync('Role')
    }
}
export default new SettingsService();