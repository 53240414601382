import './polyfills';
import React, {useReducer} from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.css';
import { HashRouter, Route } from 'react-router-dom';
import './assets/base.scss';
import Main from './Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import LoginBoxed from './domain/UserPages/LoginBoxed';
import {AccessDataProvider} from './providers/accessData';

const store = configureStore();
const rootElement = document.getElementById('root');
export const history = createBrowserHistory();

const renderApp = (Component) => {

  ReactDOM.render(
    <Provider store={store}>      
        <HashRouter history={history}>
          <AccessDataProvider>
          <Route path="/login" component={LoginBoxed} />
          <Component />
          </AccessDataProvider>
        </HashRouter>      
    </Provider>,
    rootElement
  );
};

renderApp(Main);

// if (module.hot) {
//   module.hot.accept('./Pages/Main', () => {
//     const NextApp = require('./Pages/Main').default;
//     renderApp(NextApp);
//   });
// }
serviceWorker.unregister();
