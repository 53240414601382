import cx from 'classnames';
// import HeaderRightDrawer from "./Components/HeaderRightDrawer";
import jwt_decode from "jwt-decode";
import queryString from 'query-string';
import React, { Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import apiservice from 'services/apiservice';
import PatientSearch from 'services/Patient';
import { notifyError } from '../../components/alert/Toast';
import * as crypto from '../../utils/Crypto';
import HeaderLogo from '../AppLogo';
import UserBox from './Components/UserBox';
class Header extends React.Component {
    constructor(props) {
        super(props)
        const branch = JSON.parse(localStorage.getItem('branch'));

        this.state = {
            branches: [],
            selBranch: branch !== null ? branch.id : '',
            branchName: branch !== null ? branch.branchName : '',
            mobileNumber: '',
            patients: [],
            selPatient: {}
        }
    }
    componentDidMount() {

        // const info = localStorage.getItem('info');
        // if (info) {
        //     this.setState({
        //         infoJson: JSON.parse(info)
        //     })
        // }
        this.fetchData();
    }

    fetchData = async () => {
        let bid = '';//Branch Id
        if (localStorage.getItem('user')) {
            let decoded = jwt_decode(localStorage.getItem('user'));
            bid = decoded.BId;
        }
        // await apiservice.getAsync('Setup/Branch/GetList').then((res) => {
        //     this.setState({ ...this.state, branches: res.data, selBranch: bid })
        // }).catch((err => {
        //     notifyError(err.message)
        // }))
    }

    handlebranchChange = async (name, value, { selected }) => {

        if (selected === undefined) {
            localStorage.removeItem('branch')
            this.setState({ ...this.state, selBranch: value })
        } else {
            localStorage.setItem('branch', JSON.stringify(selected));
            this.setState({ ...this.state, selBranch: value, branchName: selected.branchName })
        }

    }

    goToPatient = () => {
        if (this.state.selPatient.id) {

            const qry = { r: (this.state.mobileNumber ? crypto.encrypt(this.state.mobileNumber) : '') };
            this.props.history.push(`/m/Patient?${queryString.stringify(qry)}`)
        }
    }

    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;

        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header", headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>

                    <HeaderLogo />

                    <div className={cx(
                        "app-header__content",
                        { 'header-mobile-open': enableMobileMenuSmall },
                    )}>
                        <div className="app-header-right">
                            {/* <HeaderDots/> */}
                            {/* <DropdownList
                                allowCreate={"onFilter"} filter={true}
                                data={this.state.branches} className='form-control p-0 form-control-sm'
                                valueField='id'
                                textField='branchName'
                                placeholder={'Select a branch'}
                                onChange={this.handlebranchChange}
                                id="searchClinic" name="searchClinic"
                                style={{ width: '10rem' }}
                                value={this.state.selBranch}
                            /> */}
                            <div >
                                {/* <RWDropdownList {...{
                                    name: 'branchId', label: '', valueField: 'id', textField: 'branchName',
                                    value: this.state.selBranch, type: 'string', values: this.state.branches,
                                    error: '', touched: true
                                }} handlevaluechange={this.handlebranchChange} /> */}
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret className="widget-heading " color=''>
                                        <strong>{this.state.branchName}</strong>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            this.state.branches.length > 0 && this.state.branches.map((branch, i) => {
                                                return <DropdownItem key={i} onClick={() => this.handlebranchChange('selBranch', branch.id, { selected: branch })}>{branch.branchName}</DropdownItem>
                                            })
                                        }

                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>

                            <UserBox />
                            {/* <HeaderRightDrawer/> */}
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));