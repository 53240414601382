import React, { Component, useContext } from 'react';
// import { setToken } from '../../../reducers/StoreToken';
import Slider from "react-slick";
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import bg1 from '../../../assets/utils/images/originals/Banner1.jpg';
import bg2 from '../../../assets/utils/images/originals/Banner2.jpg';
import bg3 from '../../../assets/utils/images/originals/Banner3.jpg';
import bg4 from '../../../assets/utils/images/originals/Banner4.jpg';
import SettingsService from '../../../services/Settings';
import { USER_PAGES_LOGIN_BOX } from "../../../settings/site-navigation";
import { AccessDataContext } from '../../../providers/accessData';
import AppInfoService from 'services/appInfo';
import { notifyError } from 'components/alert/Toast';

class LoginBoxed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            invalid: false,
            checked: false
        }

        this.handleChange = this.handleChange.bind(this);

        localStorage.removeItem('user');
    }
    static contextType = AccessDataContext;

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = async (e) => {
        const { username, password, checked } = this.state;
        e.preventDefault();

        if (username !== '' && password !== '') {
            this.setState({ submitted: true });
            this.setState({ invalid: false });

            try {
                await SettingsService.postLoginBox(username, password).then((response) => {
                    if (response.data) {
                        localStorage.setItem('user', JSON.stringify({ token: response.data.jwtToken }));
                        localStorage.setItem('refresh_token', JSON.stringify({ refresh_token: response.data.refreshToken }))
                        localStorage.setItem('info', JSON.stringify({ name: response.data.name, roleId: response.data.roleId }));
                        this.props.history.push(USER_PAGES_LOGIN_BOX)
                        this.appInfo();
                    }
                });
            }
            catch (error) {
                this.setState({ invalid: true });
            }
        }
    }

    appInfo = async () => {
        const { dispatch } = this.context;
        await AppInfoService.getInfo('Auth/Info').then(res => {
            dispatch({ type: 'Data', payload: res.data })
        }).catch(err => {
            notifyError(err.message)
        })
    }

    render() {

        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            initialSlide: 0,
            autoplay: true,
            adaptiveHeight: true

        };

        return (
            <div className="h-100" style={{ position: 'fixed' }}>
                <Row className="h-100 no-gutters">
                    <Col lg="4" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider  {...settings}>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg1 + ')'
                                        }} />
                                    {/* <div className="slider-content">
                                        <h3>Perfect Balance</h3>
                                        <p>
                                            ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of unified React Boostrap Components and Elements.
                                            </p>
                                    </div> */}
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }} />
                                    {/* <div className="slider-content">
                                        <h3>Scalable, Modular, Consistent</h3>
                                        <p>
                                            Easily exclude the components you don't require. Lightweight, consistent
                                            Bootstrap based styles across all elements and components
                                            </p>
                                    </div> */}
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                    <div className="slide-img-bg opacity-6"
                                        style={{
                                            backgroundImage: 'url(' + bg2 + ')'
                                        }} />
                                    {/* <div className="slider-content">
                                        <h3>Complex, but lightweight</h3>
                                        <p>
                                            We've included a lot of components that cover almost all use cases for
                                            any type of application.
                                            </p>
                                    </div> */}
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                    <div className="slide-img-bg opacity-6"
                                        style={{
                                            backgroundImage: 'url(' + bg4 + ')'
                                        }} />
                                    {/* <div className="slider-content">
                                        <h3>Complex, but lightweight</h3>
                                        <p>
                                            We've included a lot of components that cover almost all use cases for
                                            any type of application.
                                            </p>
                                    </div> */}
                                </div>
                            </Slider>
                        </div>
                    </Col>
                    <Col lg="8" md="12" className="p-5 h-100 d-flex bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            <div className="app-logo mb-1" style={{
                                backgroundRepeat: 'no-repeat'
                            }} />
                            <h6 className="mb-0">
                                <span>Please sign in to your account.</span>
                            </h6>
                            <Row className="divider" />
                            <Form name="lform" onSubmit={this.handleSubmit}>
                                {/* <div className="modal-body"> */}

                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input type="text" name="username" id="exampleEmail"
                                                placeholder="Username here..." onChange={this.handleChange}
                                                className={`form-control ${this.state.username === '' && this.state.submitted && 'is-invalid'}`} />

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input type="password" name="password" id="examplePassword"
                                                placeholder="Password here..." onChange={this.handleChange}
                                                className={`form-control ${this.state.username === '' && this.state.submitted && 'is-invalid'}`} />

                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup check>
                                    <Input type="checkbox" name="check" id="exampleCheck" />
                                    <Label for="exampleCheck" check>Keep me logged in</Label>
                                </FormGroup>

                                {this.state.invalid ? <Label >Invalid username and password.</Label> : this.state.submitted ? <Label >Verifying...</Label> : ""}
                                {/* <div className="divider" /> */}
                                {/* <h6 className="mb-0">
                                        No account?{' '}
                                        <a href="#/" className="text-primary">Sign up now</a>
                                    </h6> */}
                                {/* </div> */}
                                {/* <div className="modal-footer clearfix">
                                    <div className="float-left">
                                        <a href="#/" className="btn-lg btn btn-link">Recover
                                        Password</a>
                                    </div>
                                    <div className="float-right">
                                        <Button type="submit" color="primary" size="lg" >Login to Dashboard</Button>
                                    </div>
                                </div> */}
                                <Row className="divider" />
                                <div className="d-flex align-items-center">
                                    <div className="ml-auto">
                                        <Button type="submit" className="btn-primary1" size="lg" >Login</Button>
                                    </div>
                                </div>
                            </Form>
                            <div className="text-center opacity-8 mt-3">
                              {process.env.REACT_APP_API_ENV}&nbsp;|&nbsp;Ver&nbsp;{process.env.REACT_APP_VERSION}  Copyright &copy; Avontix Global 2021
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default LoginBoxed;
